import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ProjectCards from '../components/projectcards'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const cards = data?.cards;
  const allImageContent = data?.allImageContent;

  cards.edges.map(item => {

    // fetches the image based on the value that that is set on the appropriate json element
    const imageForCard = allImageContent.edges.find(
      element =>
        element.node.fluid.originalName === item.node.image.src
    )
    item.imageForCard = imageForCard;

  });
  return (

    <Layout location={location} title={siteTitle} fullScreen={false}>
      <ProjectCards cards = {cards} allImageContent = {allImageContent}/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    cards: allCardsJson {
        edges {
          node {
            id
            title
            description
            link
            category
            image {
              src
            }
          }
        }
      }
  
      allImageContent: allImageSharp {
        edges {
          node {
            fluid(maxWidth: 300) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

  }
`
