import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ProjectCard from "./ProjectCard.js";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    display:"flex",
    flexGrow: 1
  },

  maincontent: {
    width:"100%",
    background: "rgba(0, 0, 0, 0)",
    
    paddingBottom: theme.spacing(3)
  },
  content4: {
    paddingBottom: theme.spacing(3)
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "black"
  },
  toolbar: theme.mixins.toolbar,
  title: {
    paddingBottom: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  picture: {
    display: "flex", 
    justifyContent: "center",
    alignItems: "center"
  },
  stress: {
    color: "rgb(0, 0, 0)",
    textDecoration: "underline dotted"
  },
  linktab:{
    fontSize: "0.6em",  
    whiteSpace: "nowrap", 
    alginSelf:"center"
  },
  tabs:{
    inkBarStyle:"black",
    indicatorColor:"black",
    color: "black",
    fontWeight: "light"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function FullWidthGrid({ cards }) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <main className={classes.root}>
  
        <div className={classes.maincontent} id="projects">

          <Container >
     
              <Tabs
                TabIndicatorProps={{style: {background:'black'}}}
                className={classes.tabs}
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <LinkTab className={classes.linktab}  label="SELECTED WORK"  {...a11yProps(0)} />
                <LinkTab className={classes.linktab}  label="AR/MR"  {...a11yProps(1)} />
                <LinkTab className={classes.linktab}  label="INSTALLATION"  {...a11yProps(2)} />
                <LinkTab className={classes.linktab}  label="WEB" {...a11yProps(3)} />
              </Tabs>

            <div className={classes.content4} />
            <TabPanel value={value} index={0}>

            <Grid container space = {2} >
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={5}>
                    {
                        cards.edges.map(tile => {
                            if(tile.node.category.includes("SELECTEDWORK")){

                            return(
                                <Grid item key={tile.node.title} >
                                    <ProjectCard data = {tile}></ProjectCard>
                                </Grid >
                            )
                            }
                            else 
                            {
                              return null;
                            }
                        })
                    }
                    </Grid>
               </Grid>

            </Grid>
             
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Grid container space = {2} >
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={5}>
                    {
                        cards.edges.map(tile => {
                            if(tile.node.category.includes("AR/MR")){

                              return(
                                  <Grid item key={tile.node.title} >
                                      <ProjectCard data = {tile}></ProjectCard>
                                  </Grid >
                              )
                            }
                            else 
                            {
                              return null;
                            }
                        })
                    }
                    </Grid>
               </Grid>

            </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Grid container space = {2} >
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={5}>
                    {
                        cards.edges.map(tile => {
                            if(tile.node.category.includes("INSTALLATION")){

                            return(
                                <Grid item key={tile.node.title} >
                                    <ProjectCard data = {tile}></ProjectCard>
                                </Grid >
                            )
                            }
                            else 
                            {
                              return null;
                            }
                        })
                    }
                    </Grid>
               </Grid>

            </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <Grid container space = {2} >
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={5}>
                    {
                        cards.edges.map(tile => {
                            if(tile.node.category.includes("WEB")){

                            return(
                                <Grid item key={tile.node.title} >
                                    <ProjectCard data = {tile}></ProjectCard>
                                </Grid >
                            )
                            }
                            else 
                            {
                              return null;
                            }
                        })
                    }
                    </Grid>
               </Grid>

            </Grid>
            </TabPanel> 

            </Container>
        </div>
    </main>
  );
}

