import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";
import Img from "gatsby-image";
const useStyles = makeStyles({
  card: {
    maxWidth: 300,
    maxHeight: 500,
    background: "rgba(255, 255, 255, 0.06)",
    color: "white"
  },
  cardContent:{
    //margiin:"auto",
    textAlign:"center"

  },
  cardtext:{
  
    textAlign:"center"

  },
  title:{
    fontSize:"0.8em"
  },
  detail:{
    fontSize:"0.6em"
  },
  media: {
    width: 300,
    height: 300,
    margin: "auto",
    
  },
  underlineNone: {
    textDecoration: "none",
    color: "white"
  }
});

export default function ProjectPreview(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Link to={props.data.node.link} className={classes.underlineNone}>
        <CardActionArea className={classes.cardActionArea}>
      
          <CardMedia       
            className={classes.media}
            title={props.data.node.title} 
          >
          <Img 
            key={props.data.node.title+"image"} 
            width={200} 
            fluid={props.data.imageForCard!== undefined ?props.data.imageForCard.node.fluid:null} 
          />        
  
          </CardMedia>

          <CardContent className={classes.cardContent}>

  
          <div className={classes.cardtext}>
          <Typography className={classes.title}gutterBottom variant="subtitle2" component="h6">
           {props.data.node.title}
          </Typography>
          <Typography
              gutterBottom
              className={classes.detail}
              variant="subtitle2"
              component="h6"
          >
          {props.data.node.description}
          </Typography>

            </div>
          </CardContent>

        </CardActionArea>

      </Link>
    </Card>
  );
}
